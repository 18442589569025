import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@core/environments/environment';
import {
  AccountInitiator,
  AccountSignatory,
  AccountsAttachedToUser,
  AccountsAttachedToUserResponse,
  AssignSignatory,
  DeactivateSignatory,
  ProfilesAttachedToAccount,
  UpdateAccountInfo,
} from '@core/models/client/account';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  http = inject(HttpClient);
  BASE_URL = environment.CLIENT_BASE_URL + '/v1/account';

  constructor() {}

  assignSignatory(signatoryInfo: AssignSignatory) {
    return this.http.post(this.BASE_URL, signatoryInfo);
  }

  updateAccountInfo(accountInfo: UpdateAccountInfo) {
    return this.http.patch(this.BASE_URL, accountInfo);
  }

  accountInitiator(accountInitiator: AccountInitiator) {
    return this.http.post(this.BASE_URL, accountInitiator);
  }

  getProfilesAttachedToAccount(accountInfo: ProfilesAttachedToAccount) {
    return this.http.post(this.BASE_URL, accountInfo);
  }

  getAccountsAttachedToUsers(profileInfo: AccountsAttachedToUser): Observable<AccountsAttachedToUserResponse> {
    return this.http.post<AccountsAttachedToUserResponse>(`${this.BASE_URL}/get-profile-accounts`, profileInfo);
  }

  deactivateSignatory(signatoryInfo: DeactivateSignatory) {
    return this.http.post(this.BASE_URL, signatoryInfo);
  }

  getAccountSignatories(accountInfo: AccountSignatory) {
    return this.http.post(this.BASE_URL, accountInfo);
  }
  getIndividualAccountKyc(account_id: string){
    return this.http.get(`${this.BASE_URL}/get-individual-account-kyc?account_id=${account_id}`)
  }
  getCoperateAccountKyc(account_id: string){
    return this.http.get(`${this.BASE_URL}/get-corporate-account-kyc?account_id=${account_id}`)
  }
}
