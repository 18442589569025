import { Injectable, NgZone, OnDestroy, inject } from '@angular/core';
import { AuthService } from '@app/core/services/client/auth.service';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AuthActions } from './auth.actions';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SIGNUP_TOKEN } from '@app/core/constants/app.constant';
import { AccountService } from '@app/core/services/client/account.service';

export interface AuthStateModel {
  token: string | null;
  id: string | null;
  isAccountSetup: boolean | null;
  loading: boolean | null;
  success: boolean;
  error: boolean | null;
  account_id: string | null;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    id: null,
    isAccountSetup: null,
    loading: null,
    success: false,
    error: null,
    account_id: null,
  },
})
@Injectable()
export class AuthState {
  subs: Subscription[] = [];

  authService = inject(AuthService);
  accountService = inject(AccountService)
  store = inject(Store);
  router = inject(Router);
  private ngZone = inject(NgZone);

  @Action(AuthActions.Login)
  async login(ctx: StateContext<AuthStateModel>, action: AuthActions.Login) {
    const state = ctx.getState();

    this.store.dispatch(new AuthActions.SetLoadingStatus(true));
    this.authService.login(action.payload).subscribe({
      next: (response) => {

        ctx.setState({
          ...state,
          token: response.token,
          id: response.id,
          isAccountSetup: response.is_account_setup,
          loading: false,
          success: true,
          error: null,
        });

        // Call another endpoint to fetch the account profile list
        this.accountService.getAccountsAttachedToUsers({ user_identifier: response.id }).subscribe({
          next: (accountList) => {
            // If the list is empty, redirect to the account type creation screen
            if (accountList.data.length === 0) {
              this.ngZone.run(async () =>
                this.router.navigateByUrl('/create-account/type')
              );
            } else {
              // If the list has items, redirect to the dashboard

              this.ngZone.run(async () =>
                this.router.navigateByUrl('dashboard')
              );
            }
          },
          error: (error) => {
            // Handle any error that occurs during fetching the account profile list
            console.error('Error fetching account profile list:', error);
            this.store.dispatch(new AuthActions.SetLoadingStatus(false));
          }
        });
      },
      error: (error) => {
        // If the error status is 409, redirect to verification page
        if (error.status === 409) {
          localStorage.setItem(SIGNUP_TOKEN, error.error.token);
          this.ngZone.run(async () =>
            this.router.navigateByUrl('/create-account/verification')
          );
          return;
        }
  
        // For other errors, set loading to false and reset the auth state
        this.store.dispatch(new AuthActions.SetLoadingStatus(false));
        this.store.reset({ auth: {} });
      },
    });
  }

  @Action(AuthActions.SetLoadingStatus)
  setLoading(
    ctx: StateContext<AuthStateModel>,
    action: AuthActions.SetLoadingStatus
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      loading: action.payload,
    });
  }

  @Action(AuthActions.Signup)
  signup(ctx: StateContext<AuthStateModel>, action: AuthActions.Signup) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      id: action.payload.userId,
      token: action.payload.token,
      // isAccountSetup: true,
      loading: false,
      success: true,
      error: null,
    });
}
  @Action(AuthActions.SetAccountID)
  createAccount(ctx: StateContext<AuthStateModel>, action: AuthActions.SetAccountID) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      account_id: action.account_id,
    });
}

}
